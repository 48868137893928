import React, { useState, useRef, useEffect, useCallback, Suspense, useTransition } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { format } from "date-fns";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TbArrowsDiagonal } from "react-icons/tb";
import { FiLogOut } from 'react-icons/fi';

import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import { MdMessage } from "react-icons/md";

import LoadingSpinner from '../components/Animation/LoadingSpinner';
import AnimatedButtonLayout from '../components/Animation/AnimatedButtonLayout';
import SpectrumAnimation from '../components/Animation/SpectrumAnimation';
import ChatHistory from '../components/Chat/ChatHistory';
// import { createNewChat, saveChatToHistory, updateChatInHistory } from '../utils/chatHistoryUtils';

import { customBranding } from '../config/config';
import { useChatbotConfig } from '../contexts/ChatbotConfigContext';
// import { extractAccessToken, initializeChatbot } from '../utils/authUtils';
// import TokenExtractor from '../utils/tokenExtractor';
import { 
  createNewChat, 
  getChatMessages, 
  saveChatMessages,
  updateChatHistory 
} from '../utils/chatStorageUtils';

const Talk = React.lazy(() => import('../components/Talk/Talk'));
const Chat = React.lazy(() => import('../components/Chat/Chat'));

const MessageContent = ({ 
  message, 
  config, 
  customBranding, 
  toggleAudioPlayback, 
  audioPlaying,
  handleToggleSpeech,
  playingMessageId,
  maxLength = 150
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderExpandableText = (text) => {
    // Add null check for text
    if (!text) return '';
    
    const needsExpansion = text.length > maxLength;
    const truncatedText = needsExpansion && !isExpanded 
      ? text.slice(0, maxLength) + '...'
      : text;

    return (
      <div className="relative">
        <AnimatePresence mode="wait">
          <motion.div
            key={isExpanded ? 'expanded' : 'collapsed'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="whitespace-pre-line text-justify hyphens-auto"
          >
            {truncatedText}
          </motion.div>
        </AnimatePresence>
        
        {needsExpansion && (
          <div className="flex justify-center w-full">
          <motion.button
            onClick={() => setIsExpanded(!isExpanded)}
            className="flex justify-center items-center w-8 h-8 rounded-full hover:bg-black/5 transition-colors"
            style={{ color: config.color }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <motion.div
              animate={{ rotate: isExpanded ? 180 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <FaIcons.FaChevronDown className="w-4 h-4" />
            </motion.div>
          </motion.button>
        </div>
        )}
      </div>
    );
  };

  if (message.isAudio) {
    return (
      <div className="flex flex-col">
        <div className="flex items-center">
          {message.type === 'user' ? (
            <button
              onClick={() => toggleAudioPlayback(message.audioUrl)}
              className="mr-2 p-2 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{ backgroundColor: customBranding.audioUserPlayColor }}
            >
              {audioPlaying === message.audioUrl ? (
                <RiIcons.RiPauseFill size={20} />
              ) : (
                <RiIcons.RiPlayFill size={20} />
              )}
            </button>
          ) : (
            <button
              onClick={() => handleToggleSpeech(message.text || '', message.id)}
              className="mr-2 p-2 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{ backgroundColor: customBranding.audioBotPlayColor }}
            >
              {playingMessageId === message.id ? (
                <RiIcons.RiPauseFill size={20} />
              ) : (
                <RiIcons.RiPlayFill size={20} />
              )}
            </button>
          )}
          <span>{message.content}</span>
        </div>
        <div className="flex justify-between text-xs mt-1">
          <div style={{ color: message.type === 'bot' ? customBranding.audioBotTimeTextColor : customBranding.audioUserTimeTextColor }}>
            {message.audioDuration}
          </div>
          <div style={{ color: message.type === 'bot' ? customBranding.audioBotTimeStampColor : customBranding.audioUserTimeStampColor }}>
            {format(new Date(message.timestamp), "hh:mm a")}
          </div>
        </div>
        <div style={{ color: customBranding.audioBotTranscriptColor }}>
          {renderExpandableText(message.text)}
        </div>
      </div>
    );
  }

  if (message.isRecording) {
    return (
      <div className="flex flex-col items-start space-y-1">
        <div className="flex items-center space-x-2">
          <SpectrumAnimation />
          <span>{message.content || ''}</span>
        </div>
      </div>
    );
  }

  return renderExpandableText(message.content);
};

const VoiceBot = () => {
  const [isInChat, setIsInChat] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [recordingState, setRecordingState] = useState("Initial");
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [showTalk, setShowTalk] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [showStatusForm, setShowStatusForm] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [currentSpeech, setCurrentSpeech] = useState(null);
  const [playingMessageId, setPlayingMessageId] = useState(null);
  const [speechDetected, setSpeechDetected] = useState(false);
  const { config } = useChatbotConfig();
  const [messages, setMessages] = useState([
    {
      type: "bot",
      content: config.greetingMessage || "How can I help you?",
      timestamp: new Date(),
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const chatBoxRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [activeSection, setActiveSection] = useState("Initial");
  const [isPending, startTransition] = useTransition();
  const [isLoggedIn, setIsLoggedIn] = useState(false);


   // New states for chat history
   const [showChatHistory, setShowChatHistory] = useState(false);
   const [currentChat, setCurrentChat] = useState(null);
  //  const [messages, setMessages] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const containerRef = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const lastScrollHeight = useRef(0);
  const lastScrollTop = useRef(0);

  // useEffect(() => {
  //   const container = containerRef.current;
  //   if (!container) return;
  
  //   if (container.scrollHeight !== lastScrollHeight.current) {
  //     const scrollDifference = container.scrollHeight - lastScrollHeight.current;
      
  //     // Auto-scroll if user sent the message or was already near bottom
  //     const shouldAutoScroll = 
  //       messages[messages.length - 1]?.type === "user" || 
  //       autoScroll;
  
  //     if (shouldAutoScroll) {
  //       container.scrollTop = container.scrollHeight;
  //     } else if (!autoScroll) {
  //       container.scrollTop = lastScrollTop.current + scrollDifference;
  //     }
  
  //     lastScrollHeight.current = container.scrollHeight;
  //     lastScrollTop.current = container.scrollTop;
  //   }
  // }, [messages, autoScroll]);
  
  // Also update the handleScroll function to be more forgiving with the bottom threshold:
  // const handleScroll = (e) => {
  //   const container = e.target;
  //   // Increased threshold to 150px
  //   const isScrolledNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 150;
  //   setAutoScroll(isScrolledNearBottom);
  //   lastScrollTop.current = container.scrollTop;
  // };

  
  // New state for token data
  // const [tokenData, setTokenData] = useState({
  //   accessToken: null,
  //   url: null
  // });


  // Token extraction handler
  // const handleTokenExtracted = (data) => {
  //   // Force error logging for testing
  //   if (!data.success) {
  //     console.group('Chatbot Initialization Error');
  //     console.warn('Extraction failed:', data.error);
  //     console.log('URL:', data.url);
  //     console.log('Timestamp:', new Date().toISOString());
  //     console.groupEnd();
  //   }
  // };

  // useEffect(() => {
  //   const initialize = async () => {
  //     try {
  //       const initData = await initializeChatbot();
  //       setIsInitialized(true);
        
  //       // You can use the initialization data if needed
  //       console.log('Chatbot initialized:', initData);
        
  //       // Optionally set any initial state based on the response
  //       setMessages([
  //         {
  //           type: "bot",
  //           content: initData.welcomeMessage || "How can I help you?",
  //           timestamp: new Date(),
  //         },
  //       ]);
  //     } catch (error) {
  //       toast.error('Failed to initialize chatbot. Please refresh the page.');
  //       console.error('Initialization error:', error);
  //     }
  //   };

  //   initialize();
  // }, []);

  // Add loading state check
  // if (!isInitialized) {
  //   return null; // Or return a loading spinner
  // }

  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    setShowStatusForm(false);
    toast.success("Logged out successfully!");
    setMessages(prevMessages => [
      ...prevMessages,
      {
        type: "bot",
        content: "You have been logged out successfully.",
        timestamp: new Date()
      }
    ]);
  }, [setMessages]);

  const toggleChat = useCallback(() => {
    startTransition(() => {
      setIsChatOpen((prevState) => {
        const newState = !prevState;
        // Send message to parent when chat is opened or closed
        window.parent.postMessage(newState ? 'chatOpened' : 'chatClosed', '*');
        return newState;
      });
    });
  }, []);

  
  useEffect(() => {
    // Send initial state to parent
    window.parent.postMessage('chatClosed', '*');
  }, []);

 // Function to handle expand button click
//  const handleExpand = useCallback(() => {
//   const currentUrl = window.location.href;
//   console.log('Current URL:', currentUrl);
//   const newWindow = window.open(currentUrl, '_blank');
//   console.log('New window:', newWindow);
//   if (newWindow) newWindow.focus();
// }, []);
const handleExpand = useCallback(() => {
  console.log("Expand button clicked!");
  try {
    const currentUrl = window.location.href;
    let parentUrl = document.referrer; // Fallback for parent URL
      // Define accessToken for localhost
      if (currentUrl.includes("localhost") || currentUrl.includes("https://chatbot.bolofy.com/")) {
      if (!config.accessToken) {
        config.accessToken =
          "eyJpdiI6ImZneWtXWm8wR1N1ZFJ6WktpaVJ3b2c9PSIsInZhbHVlIjoic1EzTThpcG0vT3JON0JQWllPVUxKYUk1R0dOeVV4WFVtKzFBMkxsYnprSDhwdnBYWGtlQnFGTS9EbFE3M1o1VEY4SWVENy81MVUxaWM1WFQ5RVZnM3J3bGo2SXQ0cGRnUmNHRTduRGZjT0RLemxkNWtyb1RZY0tnYWxRVTVuZkkiLCJtYWMiOiIwNzUyYmQxMzhkYzkzMmYyM2U2ZTAyNzZhZmRiNGI0OTdiYjk2MDliMjY0ZDRmM2NhYzkyOGUxMjYzZTFhOTIyIiwidGFnIjoiIn0=";
        console.log("Access token set for localhost:", config.accessToken);
      }

      // Function to update the current window's URL with reloading
      const updateCurrentWindowUrl = (fullParentUrl) => {
        console.log("Updating current window URL...");
        const baseUrl = currentUrl.split("?")[0]; // Remove existing query params

        // Add query parameters
        const params = new URLSearchParams();
        if (config.accessToken) params.set("accessToken", config.accessToken);
        params.set("url", encodeURIComponent(fullParentUrl));

        const updatedUrl = `${baseUrl}?${params.toString()}`;
        console.log("Updated current window URL:", updatedUrl);

        window.history.replaceState({}, '', updatedUrl);
      };
      updateCurrentWindowUrl(parentUrl);
    }
    // Function to process the expand action
    const proceedWithExpand = (fullParentUrl) => {
      console.log('Full parent URL:', fullParentUrl);

      // Update the current window URL
      // updateCurrentWindowUrl(fullParentUrl);

      // Parse the full parent URL
      const parentUrlObj = new URL(fullParentUrl);
      const fullPath = `${parentUrlObj.origin}${parentUrlObj.pathname}${parentUrlObj.search}`;

      // Add config and parent URL to parameters
      const configParams = encodeURIComponent(JSON.stringify(config));
      const separator = currentUrl.includes('?') ? '&' : '?';

      // Construct the expanded URL
      const expandedUrl = `${currentUrl}${separator}expandedConfig=${configParams}&url=${encodeURIComponent(fullPath)}`;

      // Verify URL construction
      const urlCheck = {
        original: fullPath,
        fullPathname: parentUrlObj.pathname,
        encoded: encodeURIComponent(fullPath),
        decoded: fullPath,
      };
      console.log('URL parameter check:', urlCheck);

      // Open new window
      const newWindow = window.open(expandedUrl, '_blank');
      if (newWindow) newWindow.focus();
    };

    // Check if running in an iframe
    if (window.parent !== window) {
      console.log("Running inside an iframe, using postMessage to fetch parent URL.");

      // Listen for the parent URL from first logic
      window.addEventListener(
        'message',
        function (event) {
          if (event.data.type === 'PARENT_URL_PATH') {
            parentUrl = event.data.fullUrl; // Get full URL with path from parent
            proceedWithExpand(parentUrl);
          }
        },
        { once: true }
      );

      // Send message to parent to fetch the URL
      window.parent.postMessage({ type: 'GET_PARENT_URL' }, '*');
    } else {
      console.log("Not running in an iframe, using fallback for parent URL.");

      // If not in iframe, fallback to referrer or direct update
      proceedWithExpand(parentUrl || currentUrl); // Use fallback or current URL
    }
  } catch (error) {
    console.error('Error in handleExpand:', error);
  }
}, [config]);

// Add this event listener to the parent page
window.addEventListener('message', function (event) {
  if (event.data.type === 'GET_PARENT_URL') {
    event.source.postMessage(
      {
        type: 'PARENT_URL_PATH',
        fullUrl: window.location.href, // Send the parent URL
      },
      '*'
    );
  }
});




// Check if the bot is running in a new window
const isExpandedWindow = window.opener != null;

useEffect(() => {
  if (isExpandedWindow) {
    // Get the configuration from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const expandedConfig = urlParams.get('expandedConfig');
    
    if (expandedConfig) {
      try {
        const parsedConfig = JSON.parse(decodeURIComponent(expandedConfig));
        // Update the config through context
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { setConfig } = useChatbotConfig();
        setConfig(parsedConfig);
      } catch (error) {
        console.error('Error parsing expanded config:', error);
      }
    }
  }
}, [isExpandedWindow]);

  const handleToggleSpeech = useCallback((text, messageId) => {
    if (playingMessageId === messageId) {
      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.pause();
        setPlayingMessageId(null);
      } else {
        window.speechSynthesis.resume();
        setPlayingMessageId(messageId);
      }
    } else {
      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.onstart = () => {
        setPlayingMessageId(messageId);
      };
      utterance.onend = () => {
        setPlayingMessageId(null);
      };
      utterance.onpause = () => setPlayingMessageId(null);
      utterance.onresume = () => setPlayingMessageId(messageId);
      window.speechSynthesis.speak(utterance);
      setCurrentSpeech({ utterance, messageId });
    }
  }, [playingMessageId]);

  // const getWelcomeMessage = useCallback((section) => {
  //   switch (section) {
  //     case "chat":
  //       return "Welcome to the chat! How can I assist you today?";
  //     case "talk":
  //       return "Welcome to the talk section! You can speak your message.";
  //     default:
  //       return "How can I help you?";
  //   }
  // }, []);


    // Load messages when current chat changes
    useEffect(() => {
      if (currentChat?.id) {
        const savedMessages = getChatMessages(currentChat.id);
        setMessages(savedMessages.length > 0 ? savedMessages : [
          { type: "bot", content: config.greetingMessage || "How can I help you?" , timestamp: new Date() }
        ]);
      }
    }, [currentChat?.id]);
  
    // Save messages when they change
    useEffect(() => {
      if (currentChat?.id && messages.length > 0) {
        saveChatMessages(currentChat.id, messages);
      }
    }, [currentChat?.id, messages]);
    
    const handleStartNewChat = useCallback(() => {
      startTransition(() => {
        const newChat = createNewChat();
        setCurrentChat(newChat);
        setShowChatHistory(false);
        setShowChat(true);
        setActiveSection("chat");
      setMessages([
        { type: "bot", content: config.greetingMessage || "How can I help you?" , timestamp: new Date() }
      ]);
    });
  }, []);
  
  const handleChatClick = useCallback(() => {
    startTransition(() => {
      setShowChatHistory(true);
      setShowChat(false);
      setShowTalk(false);
      setIsInChat(true);
      setActiveSection("chat-history");
    });
  }, []);

  const handleSelectChat = useCallback((chat) => {
    startTransition(() => {
      setCurrentChat(chat);
      setShowChatHistory(false);
      setShowChat(true);
      setActiveSection("chat");
    });
  }, []);

  const handleTalkClick = useCallback(() => {
    startTransition(() => {
      setShowTalk(true);
      setShowChat(false);
      setShowChatHistory(false);
      setIsInChat(true);
      setActiveSection("talk");
      setMessages([
        { type: "bot", content: config.greetingMessage || "How can I help you?", timestamp: new Date() }
      ]);
    });
  }, []);

  const cancelOngoingRequests = useCallback(() => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
  }, [abortController]);

  const handleBackClick = useCallback(() => {
    startTransition(() => {
      if (showChat || showTalk) {
        // Save current chat state before going back
        if (currentChat?.id) {
          saveChatMessages(currentChat.id, messages);
        }
        setShowChatHistory(true);
        setShowChat(false);
        setShowTalk(false);
        setActiveSection("chat-history");
      } else if (showChatHistory) {
        setShowChatHistory(false);
        setIsInChat(false);
        setActiveSection("Initial");
      }
      cancelOngoingRequests();
      
    });
  }, [cancelOngoingRequests,showChat, showTalk, showChatHistory, currentChat, messages]);


   // Update Messages wrapper function to ensure proper storage
   const updateMessages = useCallback((newMessages) => {
    setMessages(prevMessages => {
      const updatedMessages = typeof newMessages === 'function' 
        ? newMessages(prevMessages)
        : newMessages;
      
      if (currentChat?.id) {
        saveChatMessages(currentChat.id, updatedMessages);
      }
      return updatedMessages;
    });
  }, [currentChat]);

  
  // useEffect(() => {
  //   if (chatBoxRef.current) {
  //     chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  //   }
  // }, [messages]);

  useEffect(() => {
    const container = chatBoxRef.current;
    if (!container || messages.length === 0) return;
    
    const lastMessage = messages[messages.length - 1];
  
    if (lastMessage.type === "user") {
      // For user messages - scroll to bottom immediately
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 50);
    } else if (lastMessage.type === "bot") {
      // For bot messages - scroll to show just a peek
      setTimeout(() => {
        const scrollAmount = container.scrollHeight - container.clientHeight - 150; // Adjust 100 to show more/less
        container.scrollTop = scrollAmount;
      }, 50);
    }
  }, [messages]);
  
  // Remove or comment out the handleScroll function if you have it
  
  

  
  useEffect(() => {
    const handleAudioEnded = (event) => {
      if (event.target.src === audioPlaying) {
        setAudioPlaying(null);
      }
    };

    document.addEventListener("ended", handleAudioEnded, true);

    return () => {
      document.removeEventListener("ended", handleAudioEnded, true);
    };
  }, [audioPlaying]);

  const toggleAudioPlayback = useCallback((audioUrl) => {
    if (audioPlaying === audioUrl) {
      if (currentAudio) {
        currentAudio.pause();
        setAudioPlaying(null);
      }
    } else {
      if (currentAudio && audioPlaying) {
        currentAudio.pause();
      }

      const audio = document.getElementById(audioUrl) || new Audio(audioUrl);
      audio.onplay = () => setAudioPlaying(audioUrl);
      audio.onpause = () => setAudioPlaying(null);
      audio.onended = () => setAudioPlaying(null);
      audio.play().catch(e => console.error('Play error:', e));
      setCurrentAudio(audio);
    }
  }, [audioPlaying, currentAudio]);

  const playAudioAutomatically = useCallback((audioUrl) => {
    const audio = new Audio(audioUrl);
    audio.onplay = () => setAudioPlaying(audioUrl);
    audio.onpause = () => setAudioPlaying(null);
    audio.onended = () => setAudioPlaying(null);
    audio.play().catch(e => console.error('Automatic play error:', e));
    setCurrentAudio(audio);
  }, []);



  return (
    <>
      {/* <TokenExtractor onTokenExtracted={handleTokenExtracted} /> */}
    <div className={`fixed ${isExpandedWindow ? 'inset-0' : 'bottom-4 right-4'} z-50 w-full sm:w-auto sm:max-w-sm max-w-[calc(100vw-2rem)]`}>
        {!isChatOpen && !isExpandedWindow && (
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: isChatOpen ? 0 : 1, y: isChatOpen ? 20 : 0 }}
            transition={{ delay: isChatOpen ? 0 : 0.5 }}
            className={`ml-auto block p-4 rounded-full shadow-lg  `}
            style={{
              backgroundColor: config.color,
              color: customBranding.toggleStartColor
            }}
            onClick={toggleChat}
          >
            <MdMessage size={24} />
          </motion.button>
        )}

        <AnimatePresence>
          {(isChatOpen || isExpandedWindow) && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className={`${isExpandedWindow ? 'w-screen h-screen' : 'w-full sm:w-96 h-[80vh] sm:h-[32rem]'} flex flex-col rounded-lg shadow-xl relative`}
            >
              {/* Chat header */}
              <div 
                className={`flex justify-between items-center p-3   ${isExpandedWindow ? 'rounded-none' : 'rounded-t-lg'}`} 
                style={{
                  backgroundColor: config.color,
                  color: customBranding.headerTextColor
                }}
              >
                 {isInChat && (
                  <button
                    onClick={handleBackClick}
                    className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
                    disabled={isPending}
                  >
                    <RiIcons.RiArrowLeftLine size={18} />
                  </button>
                )}
                <h2 className={`font-bold ${isExpandedWindow ? ' text-2xl' : 'text-lg'} `}>
                {showChatHistory ? "Chat History" : config.name}
                  </h2>
                  <div className="flex space-x-2">
  {isLoggedIn && (
    <button
      onClick={handleLogout}
      className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
      title="Logout"
    >
      <FiLogOut size={18} />
    </button>
  )}
  {!isExpandedWindow && (
    <>
      <button
        onClick={handleExpand}
        className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
        title="Open in new window"
      >
        <TbArrowsDiagonal size={18} />
      </button>
      <button
        onClick={toggleChat}
        className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
        disabled={isPending}
      >
        <FaIcons.FaChevronDown size={18} />
      </button>
    </>
  )}
</div>
              </div>
            {/* </div> */}
            <div className="flex-1 flex flex-col overflow-hidden">
              {!isInChat ? (
                <>
                  <div
                    className="flex flex-col h-screen"
                    style={{ backgroundColor: customBranding.footerBtnBgColor }}
                  >
                    <div className="flex-grow"
                      style={{ backgroundImage: `linear-gradient(to top, ${customBranding.secondaryColor}, ${config.color})` }}
                    >
                      <div className="flex-grow flex relative z-10 items-center justify-center mt-16">
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ type: "spring", stiffness: 260, damping: 20 }}
                          className="w-32 h-32 rounded-full bg-white shadow-lg overflow-hidden"
                        >
                          <img
                            src={config.logo_url}
                            alt={`${customBranding.name} Logo`}
                            className="w-full h-full object-contain"
                            style={{ scale: 1.05 }}
                            transition={{ duration: 0.3 }}
                          />
                        </motion.div>
                      </div>
                    </div>
                    <div className="px-4 pt-4 pb-2 m-2">
                      <AnimatedButtonLayout
                        handleTalkClick={handleTalkClick}
                        handleChatClick={handleChatClick}
                      />
                    </div>
                  </div>
                </>
                  ) : (
                    <>
                      {showChatHistory ? (
                        <ChatHistory 
                          onStartNewChat={handleStartNewChat}
                          onSelectChat={handleSelectChat}
                        />
                      ) : (
                        <>
                  {/* Chat messages area */}
                  <div
                    className="flex-1 overflow-y-auto overflow-x-hidden p-4 space-y-4 custom-scrollbar"
                    style={{ backgroundColor: customBranding.chatBgColor }}
                    ref={chatBoxRef}
                    // ref={containerRef}
                    // onScroll={handleScroll}
                  >
                    <AnimatePresence>
                      {messages.map((message, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, scale: 0.8, y: 50 }}
                          animate={{ opacity: 1, scale: 1, y: 0 }}
                          exit={{ opacity: 0, scale: 0.8, y: -50 }}
                          transition={{ type: "spring", stiffness: 100 }}
                          className={`flex ${message.type === "user" ? "justify-end" : "justify-start"}`}
                        >
                          <div className={`flex items-start ${message.type === "user" ? null : "flex-row"} max-w-[80%] sm:max-w-[70%]`}>
                            {message.type === "user" ? null : (
                              <div className="w-8 h-8 rounded-full flex-shrink-0 flex items-center justify-center mr-2">
                                <img
                                 src={config.logo_url}
                                 alt={`${config.name} Logo`}
                                  className="w-8 h-8 object-contain rounded-full"
                                />
                              </div>
                            )}
                            <div
                              className={`p-3 mt-10 -ml-10 rounded-2xl shadow-md break-words text-sm sm:text-base`}
                              style={{
                                backgroundColor: message.type === "user" ? customBranding.msgUserBgColor : customBranding.msgBotBgColor,
                                color: message.type === "user" ? customBranding.msgUserTextColor : customBranding.msgBotTextColor,
                              }}
                            >
                              {/* {message.isAudio ? (
                                <div className="flex flex-col">
                                  <div className="flex items-center">
                                    {message.type === 'user' ? (
                                      <button
                                        onClick={() => toggleAudioPlayback(message.audioUrl)}
                                        className="mr-2 p-2 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                        style={{ backgroundColor: customBranding.audioUserPlayColor }}
                                      >
                                        {audioPlaying === message.audioUrl ? (
                                          <RiIcons.RiPauseFill size={20} />
                                        ) : (
                                          <RiIcons.RiPlayFill size={20} />
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => handleToggleSpeech(message.text, message.id)}
                                        className="mr-2 p-2 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                        style={{ backgroundColor: customBranding.audioBotPlayColor }}
                                      >
                                        {playingMessageId === message.id ? (
                                          <RiIcons.RiPauseFill size={20} />
                                        ) : (
                                          <RiIcons.RiPlayFill size={20} />
                                        )}
                                      </button>
                                    )}
                                    {message.type === 'user' ? (
                                      <audio id={message.audioUrl} src={message.audioUrl} className="hidden" />
                                    ) : (
                                      <audio id={message.id} src={message.id} className="hidden" onEnded={() => setAudioPlaying(null)} />
                                    )}
                                    <span>{message.content}</span>
                                  </div>
                                  <div className="flex justify-between text-xs mt-1">
                                    <div
                                      style={{
                                        color: message.type === 'bot' ? customBranding.audioBotTimeTextColor : customBranding.audioUserTimeTextColor
                                      }}
                                    >
                                      {message.audioDuration}
                                    </div>
                                    <div
                                      style={{
                                        color: message.type === 'bot' ? customBranding.audioBotTimeStampColor : customBranding.audioUserTimeStampColor
                                      }}
                                    >
                                      {format(new Date(message.timestamp), "hh:mm a")}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      color: customBranding.audioBotTranscriptColor
                                    }}
                                  >
                                    {message.text}
                                  </div>
                                </div>
                              ) : message.isRecording ? (
                                <div className="flex flex-col items-start space-y-1">
                                  <div className="flex items-center space-x-2">
                                    <SpectrumAnimation />
                                    <span>{message.content}</span>
                                  </div>
                                  <div className="text-xs text-gray-500">
                                    {Math.floor(recordingDuration / 60)}:
                                    {(recordingDuration % 60).toString().padStart(2, "0")}
                                  </div>
                                </div>
                              ) : (
                                message.content
                              )} */}
                             <MessageContent
          message={message}
          config={config}
          customBranding={customBranding}
          toggleAudioPlayback={toggleAudioPlayback}
          audioPlaying={audioPlaying}
          handleToggleSpeech={handleToggleSpeech}
          playingMessageId={playingMessageId}
        />
                            </div>
                          </div>
                        </motion.div>
                      ))}
                    </AnimatePresence>
                    {(isTyping || isWaitingForResponse) && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="flex items-center text-gray-500 dark:text-gray-300"
                      >
                        <img
                          src={config.logo_url}
                          alt="Bolofy Logo"
                          className="w-8 h-8 mr-3 object-contain rounded-full"
                        />
                        <LoadingSpinner />
                      </motion.div>
                    )}
                  </div>
                  {/* Input area */}
                  <div className="p-2  shadow-top"
                   style={{
                    backgroundColor: customBranding.chatBgColor
                  }}>
                  <Suspense>
                    {activeSection === "talk" && (
                      <Talk
                        setMessages={setMessages}
                        isWaitingForResponse={isWaitingForResponse}
                        setIsWaitingForResponse={setIsWaitingForResponse}
                        recordingState={recordingState}
                        setRecordingState={setRecordingState}
                        recordingDuration={recordingDuration}
                        setRecordingDuration={setRecordingDuration}
                        speechDetected={speechDetected}
                        setSpeechDetected={setSpeechDetected}
                        setIsTyping={setIsTyping}
                        setIsLoading={setIsLoading}
                        setAbortController={setAbortController}
                        playAudioAutomatically={playAudioAutomatically}
                        playingMessageId={playingMessageId}
                        handleToggleSpeech={handleToggleSpeech}
                        // accessToken={tokenData.accessToken}
                      />
                    )}
                    {activeSection === "chat" && (
                      <Chat
                        // setMessages={setMessages}
                        setMessages={updateMessages} 
                        isWaitingForResponse={isWaitingForResponse}
                        setIsWaitingForResponse={setIsWaitingForResponse}
                        setIsTyping={setIsTyping}
                        setAbortController={setAbortController}
                        showContactForm={showContactForm}
                    setShowContactForm={setShowContactForm}
                    showStatusForm={showStatusForm}
                    setShowStatusForm={setShowStatusForm}
                    currentChatId={currentChat?.id} // Add this prop
                    isLoggedIn={isLoggedIn}
                    setIsLoggedIn={setIsLoggedIn}
                      />
                    )}
                        </Suspense>
                  </div>
                  </>
                    )}

                </>
              )}
              {/* Powered by Bolofy footer */}
              <div className="p-2 bg-white text-gray-400 text-center text-xs rounded-b-lg">
                Powered by <span style={{ color: config.color }}>{`${config.name} `}</span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
    </>
  );
};
export default VoiceBot;