// Configuration constants

export const maxMessageLength = 1000;
export const maxRecordingDuration = 15;

export const apiEndpoints = {
  chat: "https://bolofy.com/api/chatBot/get-message",
  generateAudio: "https://bolofy.com/api/chatBot/get-message",
  leadCapture: "https://bolofy.com/api/botCallLog/bot-lead-capture",
  chatbot_config: "https://bolofy.com/api/chatBot",
};

export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikpva";

export const validationRegex = {
  name: /^[a-zA-Z\s]{2,50}$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  phone: /^\+?[1-9]\d{1,14}$/
};

// New custom branding configuration
export const customBranding = {
  color: "#2888b5",
  primaryShadowColor: "#2888b581",
  secondaryColor: "#ffffff",
  warningColor: "#2312e81888b5", 
  sendColor: "#2888b5", 
  toggleStartColor: "#ffffff", 
  footerBtnBgColor: "#ffffff", 
  chatBgColor: "#ffffff", 
  headerTextColor: "#ffffff", 
  msgUserTextColor: "#2a4365", 
  msgBotTextColor: "#", 
  msgUserBgColor: "#dbeafe", 
  msgBotBgColor: "#e0f2fe", 
  audioUserPlayColor: "#368dff", 
  audioBotPlayColor: "#2888b5", 
  audioBotTimeTextColor: "#2888b5",
  audioUserTimeTextColor: "#368dff",
  audioBotTimeStampColor: "#7c838f",
  audioUserTimeStampColor: "#7c838f",
  audioBotTranscriptColor: "#2888b5",
  actionBgColor: "#ffffff",
  actionTextColor: "#39424f",
  SpectrumAnimation: "#3856b8",
  LoadingSpinner: "#3f75ed",
  scrollbarColor: "#2888b5", // Scrollbar color
  scrollbarTrackColor: "#DFE9EB",  // Scrollbar track color
  name: "Bolofy",
  logo_url: "assets/bolofy_round.webp",
};