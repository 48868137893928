import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence  } from 'framer-motion';
import { format } from 'date-fns';
import { MdOutlineChat, MdDelete  } from 'react-icons/md';
import { customBranding } from '../../config/config';
import { toast } from 'react-toastify';
import { useChatbotConfig } from '../../contexts/ChatbotConfigContext';


const ChatHistory = ({ onStartNewChat, onSelectChat }) => {
  const { config } = useChatbotConfig();
  const isExpandedWindow = window.opener != null;
  // Get chat history from localStorage or state management
  const [chatHistory, setChatHistory] = useState(() => {
    try {
      const savedHistory = localStorage.getItem('chatHistory');
      return savedHistory ? JSON.parse(savedHistory) : [];
    } catch (error) {
      console.error('Error loading chat history:', error);
      return [];
    }
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);


  const handleChatSelect = useCallback((chatId) => {
    const selectedChat = chatHistory.find(chat => chat.id === chatId);
    if (selectedChat) {
      onSelectChat(selectedChat);
    }
  }, [chatHistory, onSelectChat]);

  // Animation variants
  const containerVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { 
      y: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  const handleDeleteClick = useCallback((chatId) => {
    setChatToDelete(chatId);
    setShowDeleteConfirm(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    try {
      if (chatToDelete === 'all') {
        // Clear all chat history
        localStorage.removeItem('chatHistory');
        setChatHistory([]);
        toast.success('Chat history cleared successfully');
      } else {
        // Delete single chat
        const updatedHistory = chatHistory.filter(chat => chat.id !== chatToDelete);
        localStorage.setItem('chatHistory', JSON.stringify(updatedHistory));
        setChatHistory(updatedHistory);
        toast.success('Chat deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
      toast.error('Failed to delete chat');
    }
    setShowDeleteConfirm(false);
    setChatToDelete(null);
  }, [chatToDelete, chatHistory]);

  const DeleteConfirmDialog = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
        className="bg-white rounded-lg p-6 max-w-sm mx-4"
      >
        <h3 className="text-lg font-semibold text-gray-900 mb-4">
          {chatToDelete === 'all' 
            ? 'Clear All Chat History?' 
            : 'Delete this chat?'}
        </h3>
        <p className="text-gray-600 mb-6">
          {chatToDelete === 'all'
            ? 'This will permanently delete all your chat history. This action cannot be undone.'
            : 'This chat will be permanently deleted. This action cannot be undone.'}
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setShowDeleteConfirm(false)}
            className="px-4 py-2 rounded-lg text-gray-600 hover:bg-gray-100 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteConfirm}
            className="px-4 py-2 rounded-lg text-white bg-red-500 hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </motion.div>
    </motion.div>
  );

  const EmptyState = () => (
    <motion.div 
      variants={containerVariants}
      initial="initial"
      animate="animate"
      className="flex flex-col items-center justify-center h-full space-y-4 py-8"
    >
      <motion.div 
        variants={itemVariants}
        className="bg-gray-100 p-4 rounded-full"
      >
        <MdOutlineChat size={48} className="text-gray-400" />
      </motion.div>
      <motion.h3 
        variants={itemVariants}
        className="text-gray-700 text-lg font-medium"
      >
        No Chat History
      </motion.h3>
      <motion.p 
        variants={itemVariants}
        className="text-gray-500 text-sm text-center max-w-xs"
      >
        Start a new conversation to begin chatting with our assistant
      </motion.p>
    </motion.div>
  );

  const ChatList = () => (
    <motion.div 
      variants={containerVariants}
      initial="initial"
      animate="animate"
      className="space-y-3"
    >
      {chatHistory.map((chat) => (
        <motion.div
          key={chat.id}
          variants={itemVariants}
          className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow p-4 relative group"
        >
          <div 
            className="cursor-pointer"
            onClick={() => onSelectChat(chat)}
          >
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center space-x-2">
                <span className="text-sm font-medium text-gray-800">
                  {chat.title || `Conversation ${format(new Date(chat.timestamp), 'MMM d, yyyy')}`}
                </span>
                {chat.isNew && (
                  <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                    New
                  </span>
                )}
              </div>
            </div>
            <p className="text-sm text-gray-600 line-clamp-2">
              {chat.lastMessage || 'Start of conversation'}
            </p>
            <div className="flex justify-between items-center mt-2">
            <span className="text-xs text-gray-500">
              {chat.messageCount || 0} messages
            </span>
            
            <span className="text-xs text-gray-500">
                {format(new Date(chat.timestamp), 'h:mm a')}
              </span>
          </div>
          </div>
          
          {/* Delete button */}
          <button
            onClick={() => handleDeleteClick(chat.id)}
            className="absolute right-2 top-2 p-2 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-50 opacity-0 group-hover:opacity-100 transition-opacity"
            title="Delete chat"
          >
            <MdDelete size={20} />
          </button>
        </motion.div>
      ))}
    </motion.div>
  );

  return (
    <div className="flex flex-col h-full bg-gray-50">
      <div className="flex-grow overflow-y-auto p-4">
        {chatHistory.length === 0 ? <EmptyState /> : <ChatList />}
      </div>
      
      <div className="p-4 border-t bg-white">
        <div className={`${isExpandedWindow ? 'flex justify-center  space-x-4' : 'flex flex-col space-y-3'}`}>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onStartNewChat}
            className={`${
              isExpandedWindow ? 'w-64' : 'w-full'
            } p-3 rounded-lg text-white font-medium shadow-sm flex items-center justify-center space-x-2 ${
              isExpandedWindow ? 'text-md' : ''
            }`}
            style={{ backgroundColor: config.color }}
          >
            <MdOutlineChat size={isExpandedWindow ? 20 : 20} />
            <span>Start New Conversation</span>
          </motion.button>
          
          {chatHistory.length > 0 && (
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleDeleteClick('all')}
              className={`${
                isExpandedWindow ? 'w-64' : 'w-full'
              } p-3 rounded-lg text-red-500 font-medium border border-red-500 hover:bg-red-50 transition-colors flex items-center justify-center space-x-2 ${
                isExpandedWindow ? 'text-md' : ''
              }`}
            >
              <MdDelete size={isExpandedWindow ? 20 : 20} />
              <span>Clear Chat History</span>
            </motion.button>
          )}
        </div>
      </div>

      <AnimatePresence>
        {showDeleteConfirm && <DeleteConfirmDialog />}
      </AnimatePresence>
    </div>
  );
};

export default ChatHistory;